.menuBar {
  border-bottom: 2px solid lightgray;
  width: 100%;
}
/* Create two equal columns that floats next to each other */
.columnMain {
  float: left;
  width: 75%;
  padding: 10px;
}
.columnRight {
  float: left;
  width: 25%;
  padding: 10px;
}

.onlyDesktop {
  width: 100%;
  display: block;
}
.onlyMobile {
  width: 0%;
  display: none;
}

.bottomPage {
  width: 100%;
  padding: 16px;
  background-color: rgb(235, 235, 235);
}

.date-price-page {
  width: 100%;
  padding: 16px;
  background-color: rgb(237, 249, 255);
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
/* if mobile */
@media screen and (max-width: 780px) {
  .columnMain {
    width: 100%;
  }
  .columnRight {
    width: 100%;
  }
  .column {
    width: 100%;
  }
  .onlyDesktop {
    width: 0%;
    display: none;
  }
  .onlyMobile {
    width: 100%;
    float: left;
    display: block;
  }
}
