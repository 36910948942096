.jbox-page {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  flex: 1;
}

.jbox-text-box {
  padding-right: 50px;
}

.jbox-default {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}

.jbox-ionic {
  padding-left: 0%;
  padding-right: 2%;
  padding-top: 6%;
  padding-bottom: 6%;
}

.jbox-big {
  padding-left: 16px;
  padding-right: 2%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.jbox-small {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.jbox-underline {
  border-bottom: 1px solid lightgrey;
}

.jbox-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.jbox-row-around {
  display: flex;
  flex-direction: "row";
  justify-content: space-around;
  align-items: center;
  height: fit-content;
}

.jbox-row-start {
  display: flex;
  flex-direction: "row";
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
}
.jbox-row-end {
  display: flex;
  flex-direction: "row";
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
}

.jbox-row-between {
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
  height: fit-content;
}
.jbox-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
}
.jbox-row {
  display: flex;
  flex-direction: "row";
  align-items: center;
  height: fit-content;
}
.jbox-row_wrap {
  display: flex;
  flex-direction: "row";
  align-items: self-start;

  flex-wrap: wrap;

  justify-content: flex-start;
}

.jbox-divider {
  border-bottom: 1px solid lightgrey;
}

.jbox-vertical {
  padding-top: 16;
  padding-bottom: 16;
}
